<template>
  <div>
    <div class="flex justify-between">
      <VTitle :title="title" class="section-title" />

      <VSwitch v-if="!hideAllVersions" v-model="all_versions" hide-value>
        <template #label>
          <label class="mr-2">
            {{ $t("app.all_versions") }}
          </label>
        </template>
      </VSwitch>
    </div>

    <VList
      :rows="displayedRows"
      :headers="headers"
      display-pagination
      hide-header
      :is-loading="isLoading"
      :pagination="pagination"
      @update:current_page="onUpdateCurrentPage"
      @update:per_page="onUpdatePerPage"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage :src="item.displayed_avatar" :name="item.displayed_name" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.displayed_name="{ item }">
        <div
          class="cursor-pointer"
          :class="{ 'text-theme-7 text-opacity-20': item.isInactive }"
          @click="onClickRedirectToLearner(item.learnerId)"
        >
          {{ item.displayed_name }}

          <div class="font-light text-xs">
            {{ item.displayed_username }}
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.displayed_training="{ item }">
        <div
          class="cursor-pointer"
          @click="onClickRedirectToTraining(item.trainingId)"
        >
          <div>
            {{ item.displayed_training }}
          </div>

          <div class="version">
            {{ $t("app.versions") }} {{ item?.registration?.training?.version }}
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="item.displayed_status"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.result="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="item.displayed_result"
            :class="getStatusColor(item.result)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.__ACTIONS="{ item }">
        <div class="flex justify-center">
          <VAction
            icon="preview"
            :label="$t('app.view')"
            :disabled="!isCompleted(item?.status)"
            @click="$emit('view', item)"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import usePaginate from "@/composables/usePaginate";
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
import useOptions from "@/composables/useOptions";
import useTraining from "@/composables/useTraining";
import useLearner from "@/composables/useLearner";
// Components
import VList from "@/components/tables/VList";
import VTitle from "@/components/VTitle";
import VSwitch from "@/components/inputs/VSwitch";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VAction from "@/components/tables/VAction";
// Constants
import registrationTypes from "@/constants/registrationTypes";

export default {
  components: {
    VList,
    VTitle,
    VSwitch,
    VImage,
    VChip,
    VAction
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    hideAllVersions: {
      type: Boolean,
      default: false
    },
    resourceType: {
      type: String,
      required: true,
      validator: value => Object.values(registrationTypes).includes(value)
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Composables
    const { data, pagination, isLoading, read } = useReadMultiple();
    const { getText } = useTexts();
    const { getStatusColor } = useColor();
    const { getAvatarURL, formatDate } = useDisplay();
    const { ALL_OPTIONS } = useOptions();
    const { route: trainingRoute } = useTraining();
    const { route: learnerRoute } = useLearner();

    // Data
    const all_versions = ref(false);

    // Computed
    const headers = computed(() => {
      const headers = [
        {
          value: "image",
          class: "w-10"
        },
        {
          text: t("app.name"),
          value: "displayed_name"
        },
        {
          text: t("app.started_on"),
          value: "displayed_started_on"
        },
        {
          text: t("app.completed_on"),
          value: "displayed_completed_on"
        }
      ];

      if (props.resourceType !== registrationTypes.TRAINING) {
        headers.push({
          text: t("app.trainings"),
          value: "displayed_training"
        });
      }

      if (props.resourceType === registrationTypes.EVALUATION) {
        headers.push({
          text: t("app.result"),
          value: "result",
          headerClass: "justify-center"
        });
      }

      if (!props.hideAllVersions && all_versions.value) {
        headers.push({
          text: t("app.versions"),
          value: "displayed_version"
        });
      }

      headers.push({
        text: t("app.status"),
        value: "status"
      });

      if (hasActions.value) {
        headers.push({
          text: t("app.actions"),
          value: "__ACTIONS"
        });
      }

      return headers;
    });

    const hasActions = computed(() => {
      const isEvaluation = props.resourceType === registrationTypes.EVALUATION;
      // eslint-disable-next-line
      const isAttestation = props.resourceType === registrationTypes.ATTESTATION;

      return isEvaluation || isAttestation;
    });

    const displayedRows = computed(() => {
      if (props.resourceType === registrationTypes.TRAINING) {
        return data.value.map(x => ({
          ...x,
          // eslint-disable-next-line
          displayed_avatar: getAvatarURL(x?.learner?.avatar?.view_path),
          displayed_name: `${x.learner.firstname} ${x.learner.lastname}`,
          learnerId: x?.learner?.id,
          // eslint-disable-next-line
          displayed_username: x?.learner?.username ? `@${x.learner.username}` : "",
          displayed_status: x.status ? t(`app.${x.status}`) : "",
          displayed_completed_on: formatDate({ date: x.completed_on }),
          displayed_started_on: formatDate({ date: x.started_on }),
          displayed_version: x?.training?.version,
          isInactive: x.learner.status === ALL_OPTIONS.INACTIVE.value
        }));
      }

      return data.value.map(x => ({
        ...x,
        // eslint-disable-next-line
        displayed_avatar: getAvatarURL(x?.registration?.learner?.avatar?.view_path),
        displayed_name: `${x?.registration?.learner?.firstname} ${x?.registration?.learner?.lastname}`,
        // eslint-disable-next-line
        displayed_username: x?.registration?.learner?.username ? `@${x.registration.learner.username}` : "",
        learnerId: x?.registration?.learner?.id,
        displayed_training: getText(x?.registration?.training?.texts, "name"),
        displayed_status: x.status ? t(`app.${x?.status}`) : "",
        displayed_completed_on: formatDate({ date: x?.completed_on }),
        displayed_started_on: formatDate({ date: x?.started_on }),
        displayed_version: x[props?.resourceType]?.version,
        displayed_result: x?.result ? t(`app.${x.result}`) : "",
        trainingId: x?.registration?.training?.id,
        // eslint-disable-next-line
        isInactive: x?.registration?.learner?.status === ALL_OPTIONS.INACTIVE.value
      }));
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: props.endpoint,
        pathParams: {
          id: props.id
        },
        queryParams: {
          all_versions: all_versions.value
        }
      });
    };

    const onClickRedirectToTraining = id => {
      if (!id) {
        return;
      }

      router.push({
        name: `${trainingRoute}-update`,
        params: { id }
      });
    };

    const onClickRedirectToLearner = id => {
      if (!id) {
        return;
      }

      router.push({
        name: `${learnerRoute}-update`,
        params: { id }
      });
    };

    const isCompleted = status => status === ALL_OPTIONS.COMPLETED.value;

    // eslint-disable-next-line
    const { onUpdatePerPage, onUpdateCurrentPage } = usePaginate(pagination, getData);

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    watch(all_versions, async () => {
      await getData();
    });

    return {
      onClickRedirectToLearner,
      onClickRedirectToTraining,
      headers,
      displayedRows,
      all_versions,
      isCompleted,
      // useColor
      getStatusColor,
      // useReadMultiple
      isLoading,
      pagination,
      // usePagination
      onUpdateCurrentPage,
      onUpdatePerPage
    };
  }
};
</script>
